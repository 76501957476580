import React from 'react';
import styled from 'styled-components';
import Icon from './../../assets/images/icon-magnifier.svg';
import useMedia from 'use-media';

const Container = styled.div`
    border-radius: 24px;
    width: ${props =>
        props.isDesktop ? `calc(32% - 24px)` : props.isEmpty ? '24px' : '50%'};
    height: 24px;
    color: white;
    opacity: 0.6;
    border: 1px solid white;
    display: flex;
    padding: 12px;
    transition: all 200ms ease-in-out;
    &:hover {
        opacity: 1;
        width: ${props => (!props.isDesktop ? `50%` : `calc(32% - 24px)`)};
    }
    &:active {
        opacity: 1;
        width: ${props => (!props.isDesktop ? `50%` : `calc(32% - 24px)`)};
    }
    &:focus {
        opacity: 1;
        width: ${props => (!props.isDesktop ? `50%` : `calc(32% - 24px)`)};
    }
    @media screen and (max-width: 768px) {
    }
`;
const Input = styled.input`
    font-size: 17px;
    line-height: 24px;
    height: 24px;
    color: white;
    letter-spacing: normal;
    text-align: left;
    font-family: 'Neuzeit Grotesk';
    border: none;
    margin-left: 8px;
    width: ${props => (props.isEmpty && !props.isDesktop ? '0px' : '100%')};
    outline: none;
    background: transparent;
    ${Container}:focus > & {
        width: 100%;
    }
    ${Container}:hover > & {
        width: 100%;
    }
`;

export default function Search({ value, onTextChange }) {
    const isDesktop = useMedia({ minWidth: 768 });

    return (
        <Container isDesktop={isDesktop} isEmpty={value.length === 0}>
            <img src={Icon} alt="" />
            <Input
                isDesktop={isDesktop}
                placeholder="Search"
                value={value}
                isEmpty={value.length === 0}
                onChange={e => onTextChange(e.target.value)}
            ></Input>
        </Container>
    );
}
