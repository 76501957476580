import React from 'react';
import * as PropTypes from 'prop-types';

import styled from 'styled-components';

const TitleElement = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 80px;
    line-height: 80px;
    text-align: left;
    letter-spacing: -4px;
    color: white;
    @media screen and (max-width: 767px) {
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -2.29px;
    }
`;

const TitleAlt = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: white;
    text-align: left;
    @media screen and (max-width: 767px) {
    }
`;

const typeMap = {
    blog: TitleAlt,
};

const Title = ({ children, titleType }) => {
    const Component = typeMap[titleType] || TitleElement;
    return <Component>{children}</Component>;
};

Title.defaultProps = {
    titleType: null,
};

Title.propTypes = {
    children: PropTypes.node.isRequired,
    titleType: PropTypes.string,
};

export default Title;
