import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MdTrendingFlat } from 'react-icons/md';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 64px;
    margin-right: 64px;
    justify-content: space-between;
    padding-top: 75px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding-top: 48px;
    }
`;
const ImgContainer = styled.div`
    border-radius: 4px;
    width: calc(66.66% - 16px);
    height: 448px;
    img {
        object-fit: cover;
        border-radius: 4px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 200px;
    }
`;
const Category = styled.div`
    background-color: #1d1b1e;
    color: white;
    font-family: 'Renogare-Regular';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    border-radius: 4px;
    padding: 6px;
    position: absolute;
    margin-top: -44px;
    margin-left: 8px;
    text-transform: uppercase;
`;
const Content = styled.div`
    width: calc(33.33% - 22px);
    @media screen and (max-width: 767px) {
        width: auto;
        margin: 24px;
    }
`;
const Top = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.9);
    text-align: left;
    margin-bottom: 14px;
    margin-top: 12px;
    @media screen and (max-width: 767px) {
        margin-bottom: 6px;
    }
`;
const Title = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    color: white;
    text-align: left;
    margin-bottom: 42px;
    @media screen and (max-width: 767px) {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        text-align: left;
    }
`;
const Desc = styled.div`
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    text-align: left;
    letter-spacing: normal;
    @media screen and (max-width: 767px) {
        font-size: 15px;
        line-height: 20px;
    }
`;
const MoreButton = styled.a`
    font-family: 'Renogare-Regular';
    margin-top: 24px;
    color: white;
    font-size: 12px;
    line-height: 20px;
    margin-right: 4px;
    letter-spacing: 0.5px;
    text-decoration: none;
    display: flex;
    @media screen and (max-width: 767px) {
        margin-top: 12px;
    }

    &:focus {
        opacity: 0.7;
    }
`;

export default function FeaturedBlog({
    featured_image,
    category,
    title,
    external_url,
    summary,
    url_slug,
    url = '#',
}) {
    return (
        <Container>
            <ImgContainer>
                <LazyLoadImage
                    alt={title}
                    effect="blur"
                    height={'100%'}
                    src={featured_image} // use normal <img> attributes as props
                    width={'100%'}
                />
                <Category>{category}</Category>
            </ImgContainer>
            <Content>
                <Top>Featured</Top>
                <Title>{title}</Title>
                <Desc>{summary}</Desc>
                <MoreButton href={`/blog/${url_slug}`}>
                    <span>MORE</span>
                    <MdTrendingFlat size={24} color={'white'} />
                </MoreButton>
            </Content>
        </Container>
    );
}
