import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';

const Container = styled.div`
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 767px) {
    }
`;
const Button = styled.div`
    width: 48px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid white;
    padding: 12px;
    width: 24px;
    opacity: ${props => (props.disabled ? 0.3 : 1)};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;
const PageNumbers = styled.div`
    padding: 12px;
    border-radius: 24px;
    border: 1px solid white;
    font-size: 12px;
    line-height: 24px;

    color: rgba(255, 255, 255, 0.9);
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
`;
const PageNumber = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 12px;
    line-height: 24px;
    margin: 0 12px;
    cursor: ${props => (props.isButton ? 'pointer' : 'default')};
    color: ${props =>
        props.current ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.3)'};
`;
export default function Pagination({
    onPrev,
    onNext,
    totalPages = 1,
    currentPage,
    onPage,
}) {
    const [pageNumbers, setPageNumbers] = useState([
        '1',
        '2',
        '3',
        '...',
        '12',
        '13',
    ]);
    useEffect(() => {
        let tempArray = ['1'];
        if (currentPage < 3) {
            for (let i = 2; i <= 3 && i <= totalPages; i++) tempArray.push(i);
        } else {
            tempArray.push('...');
            if (currentPage === totalPages - 3) {
                for (
                    let i = currentPage - 1;
                    i <= currentPage + 1 && i <= totalPages;
                    i++
                )
                    tempArray.push(i);
            } else {
                for (
                    let i = currentPage;
                    i <= currentPage + 2 && i <= totalPages;
                    i++
                )
                    tempArray.push(i);
            }
        }
        if (currentPage < totalPages - 2) {
            tempArray.push('...');
            tempArray.push(totalPages);
        }
        // console.log(tempArray)
        setPageNumbers(tempArray);
        return () => {};
    }, [currentPage, totalPages]);

    const handleNext = () => {
        currentPage < totalPages - 1 && onNext();
    };
    const handlePrev = () => {
        currentPage !== 0 && onPrev();
    };
    const handlePage = page => {
        page !== '...' && onPage(parseInt(page - 1));
    };

    return (
        <React.Fragment>
            {totalPages > 0 ? (
                <Container>
                    <Button
                        onClick={() => {
                            handlePrev();
                        }}
                        disabled={currentPage === 0}
                    >
                        <FaLongArrowAltLeft size={24} color="white" />
                    </Button>
                    <PageNumbers>
                        {pageNumbers.map((val, index) => {
                            return (
                                <PageNumber
                                    key={`key-${index}`}
                                    current={val - 1 === currentPage}
                                    isButton={val !== '...'}
                                    onClick={() => {
                                        handlePage(val);
                                    }}
                                >
                                    {val}
                                </PageNumber>
                            );
                        })}
                    </PageNumbers>
                    <Button
                        onClick={() => handleNext()}
                        disabled={currentPage === totalPages - 1}
                    >
                        <FaLongArrowAltRight size={24} color="white" />
                    </Button>
                </Container>
            ) : null}
        </React.Fragment>
    );
}
