import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MdTrendingFlat } from 'react-icons/md';

import Title from '../typography/Title';

const Container = styled.div`
    display: block;
    width: calc(33.33% - 22px);
    margin-right: 32px;
    &:nth-child(3n) {
        margin-right: 0;
    }
    margin-bottom: 32px;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 24px;
        margin-right: 0;
    }
`;
const ImgContainer = styled.div`
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 10 / 6.8;
    img {
        object-fit: cover;
        border-radius: 4px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Category = styled.div`
    background-color: #1d1b1e;
    color: white;
    font-family: 'Renogare-Regular';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    border-radius: 4px;
    padding: 6px;
    position: absolute;
    margin-top: -44px;
    margin-left: 8px;
    text-transform: uppercase;
`;
const Content = styled.div`
    margin: 24px;
    @media screen and (max-width: 767px) {
        margin: 24px 0 0 0;
    }
`;
const Desc = styled.div`
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    letter-spacing: normal;
    margin-top: 8px;
`;
const MoreButton = styled.a`
    font-family: 'Renogare-Regular';
    margin-top: 8px;
    color: white;
    font-size: 12px;
    line-height: 24px;
    margin-right: 4px;
    letter-spacing: 0.5px;
    text-decoration: none;
    display: flex;
    &:hover {
    }
    &:focus {
        opacity: 0.7;
    }
`;

/**
 * A blog style icon component with image and a clickable link.
 *
 * @component
 * @param {Object} blogData -data coming from the CMS's blog page except the page_slug.
 * @param {string} blogData.page_slug - the page slug the blog belongs to.
 */
export default function Blog(blogData) {
    const {
        featured_image,
        category,
        title,
        summary,
        page_slug,
        url_slug,
    } = blogData;

    /* when clicking the digital-trends image icon, we want the site directs the page to the mailChimp page instead of its original link*/
    const mailChimpUrl = 'https://retailmediaplaybook.audigent.com/whitepaper';
    const url =
        url_slug === 'retail-media-playbook'
            ? mailChimpUrl
            : `/${page_slug}/${url_slug}`;
    const target = url_slug === 'retail-media-playbook' ? '_blank' : '_self';

    return (
        <Container>
            <ImgContainer>
                <LazyLoadImage
                    alt={title}
                    effect="blur"
                    height={'100%'}
                    src={featured_image} // use normal <img> attributes as props
                    width={'100%'}
                />
                <Category>{category}</Category>
            </ImgContainer>
            <Content>
                <Title titleType="blog">{title}</Title>
                <Desc>{summary}</Desc>
                <MoreButton href={url} target={target}>
                    <span>MORE</span>
                    <MdTrendingFlat size={24} color={'white'} />
                </MoreButton>
            </Content>
        </Container>
    );
}
